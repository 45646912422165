import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-12-10T07:43:36+0000',
  },
  git: {
    branchName: 'master',
    commitHash: 'f31be5ba',
  },
};
